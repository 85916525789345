// pages/HomePage.js
import React from 'react';

// <========ButtonS========> \\
import Button from '../components/Button';

// <=========CARDS=========> \\
import CardSection from '../components/cards/CardSection';
import CardLargeImg from '../components/cards/CardLargeImg';
import CardMediumImg from '../components/cards/CardMediumImg';
import CardInfo from '../components/cards/CardInfo';

import Banner from '../components/banner';

import ImgSection from '../components/ImgSection';

import TitleSection from '../components/TitleSection';

import SplitSection from '../components/SplitSection';
import SplitItemImg from '../components/SplitItemImg';
import SplitItemList from '../components/SplitItemList';
import SplitItemText from '../components/SplitItemText';



function Coach_EuropePage() {
  return (
    <main>
      <Banner
        src="images/bannerCe.jpg"
      />
      <SplitSection>
        <SplitItemImg
          title="buy your ticket here"
          button_status="enabled"
          button_text="get your ticket"
          button_url="https://app.twizzit.com/v2/public/form/c5f7c1ea6c780b557268a11820d051ed"
          img="images/imgCe1.jpg"
        />
        <SplitItemImg
          title="host your workshop"
          button_status="enabled"
          button_text="give your workshop"
          button_url="https://app.twizzit.com/v2/public/form/e2a96ea888a36d668bbfa9a40f3fbf15"
          img="images/imgCe2.jpg"
        />
        <SplitItemImg
          title="host a small tutorial"
          button_status="enabled"
          button_text="host your tutorial"
          button_url="https://app.twizzit.com/v2/public/form/2bdc423f133edffef9ee467ce835e10c"
          img="images/imgCe3.jpg"
        />
      </SplitSection>
      <TitleSection title="what's included?" />
      <SplitSection>
        <div>
          <h5>Optional fee</h5>
          <SplitItemList
            price="We do our best to organise this event as good as possible and make it as accessible as possible. 
            Therefore we mainly only charge the expenses of the event. Nonetheless we want to compensate people organising and coaches giving sessions minimally. 
            You can support the people making this event possible by buying this optional fee of 30 EUR.
            ONLY IF you want to support the event some more and ONLY IF it's within your possibility. 
            There's no obligation to buy this ticket in any way. 
            This fee will be used to pay for travel expenses, hours, etc of the people making Coach Europe possible. "
          />
          <h3>&euro; 30</h3>
        </div>
        <div>
          <h5>All-in Weekend ticket</h5>
          <SplitItemList
            price="64.63"
            text="All activities"
            date_time="Friday 15:00 to Sunday 15:00"
          />
          <SplitItemList
            price="53.37"
            text="Sleeping place"
            date_time="Friday and Saturday"
          />
          <SplitItemList
            price="Incl in sleeping price"
            text="Breakfast "
            date_time="Saturday and Sunday"
          />
          <SplitItemList
            price="20"
            text="Meals "
            date_time="Friday evening + Saturday lunch"
          />
          <SplitItemList
            price="1"
            text="Ticket Software "
          />
          <h3>&euro; 139</h3>
        </div>
        <div>
          <h5>Weekend ticket - NO sleep</h5>
          <SplitItemList
            price="64.63"
            text="All activities"
            date_time="Friday 15:00 to Sunday 15:00"
          />
          <SplitItemList
            price="20"
            text="Meals"
            date_time="Friday evening and Saturday lunch"
          />
          <SplitItemList
            price="1"
            text="Ticket Software"
          />
          <h3>&euro; 85,63</h3>
        </div>

      </SplitSection>
      <TitleSection title="day tickets" />
      <SplitSection>
        <div>
          <h5>Day ticket <br /> (Friday + pizza)</h5>
          <SplitItemList
            price="20"
            text="Activities on Fridays"
          />
          <SplitItemList
            price="12"
            text="Pizza"
          />
          <SplitItemList
            price="1"
            text="Ticket Software"
          />
          <h3>&euro; 33</h3>
        </div>
        <div>
          <h5>Day Ticket <br /> (Saturday + lunch)</h5>
          <SplitItemList
            price="34"
            text="Activities on Saturday"
          />
          <SplitItemList
            price="5"
            text="Saturdag Lunch"
          />
          <SplitItemList
            price="1"
            text="Ticket Software"
          />
          <h3>&euro; 40</h3>
        </div>
        <div>
          <h5>Day Ticket <br /> (Sunday 08/08)</h5>
          <SplitItemList
            price="20"
            text="Activities on Sunday"
          />
          <h3>&euro; 30</h3>
        </div>
      </SplitSection>
      <TitleSection title="why you should join?" />
      <SplitSection>
        <SplitItemText title="connect" text="Connect with passionate coaches." />
        <SplitItemText title="share" text="Exchange expertise & experience." />
        <SplitItemText title="be inspired" text="Discuss diffrent challenges." />
      </SplitSection>
      <TitleSection title="optional fee" />
      <ImgSection
        title="What is the optional fee?"
        img="images/imgCe4.jpg"
        button_status="enabled"
        button_text="support coach europe"
        button_url="https://app.twizzit.com/v2/public/form/c5f7c1ea6c780b557268a11820d051ed"
        text="We strive to organize this event efficiently and make it accessible.
        We mainly charge only the event expenses, with minimal compensation for organizers and coaches.
        You can support the event by paying an optional fee of €30, if you wish and are able to.
        This fee is entirely optional and will help cover travel and other costs for those making Coach Europe possible."
      />
    </main>
  );
}

export default Coach_EuropePage;