import React from 'react';
import PropTypes from 'prop-types';

const SplitItemList = ({ price, text, date_time }) => {
  const formattedPrice = isNaN(Number(price)) ? price : `${price} EUR`;
  const formattedText = text ? ` - ${text}` : '';
  const formattedDateTime = date_time ? `(${date_time})` : '';

  return (
    <li>
      {formattedPrice}{formattedText}
      <br />
      {formattedDateTime}
    </li>
  );
};

SplitItemList.propTypes = {
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  text: PropTypes.string,
  date_time: PropTypes.string,
};

SplitItemList.defaultProps = {
  text: '',
  date_time: '',
};

export default SplitItemList;