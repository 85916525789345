// pages/HomePage.js
import React from 'react';

// <========ButtonS========> \\
import Button from '../components/Button';

// <=========CARDS=========> \\
import CardSection from '../components/cards/CardSection';
import CardLargeImg from '../components/cards/CardLargeImg';
import CardMediumImg from '../components/cards/CardMediumImg';
import CardInfo from '../components/cards/CardInfo';


import Banner from '../components/banner';

function KampenPage() {
  return (
    <main>
      <Banner
        src="images/banner.jpg"
        title='kampen'
      />
      <CardSection>
        <CardInfo
          title="breaking & freerunning "
          date="14/07 - 18/07/2025"
          age="10 - 16j"
          location="Sport Vlaanderen Br"
          price="168"
          note="
            •	Parkour (in/outdoor) <br>
            •	Tricking / salto’s (gymnastiekzaal) <br>
            •	Breaking (indoor) <br>
            <br>
            Slaap/dag kamp <br>
            <br>
            Freerunkamp waarin je volledig je eigen stijl ontwikkeld met expert lesgevers."
          button_text="meer info"
          location_url="https://www.google.com/maps/place/Sport+Vlaanderen+Brugge/@51.2062684,3.2392588,1196m/data=!3m2!1e3!4b1!4m6!3m5!1s0x47c350b9fa373b87:0x472d31056fc8ce39!8m2!3d51.2062684!4d3.2418337!16s%2Fg%2F1tf5jszl?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
          button_url="https://luwio.sport.vlaanderen/aanbod/freerunning-breaking-en-tricking"
          button_status="enabled"
        />
        <CardInfo
          title="skateboarden & freerunning  "
          date="18/08 - 22/08/2025"
          age="10 - 16j"
          location="Sport Vlaanderen Br"
          price="168"
          note="
          •	Parkour (in/outdoor)<br>
          •	Skaten (in/outdoor)<br>
          <br>
          Slaap/dag kamp
          <br>
          Neem je eigen skatemateriaal mee (skate, skeeler, step, bmx, etc)<br>
          <br>
          Ontdek Brugge door de bril van een Skater en Freerunner. Avontuur gegarandeert."
          button_text="meer info"
          location_url="https://www.google.com/maps/place/Sport+Vlaanderen+Brugge/@51.2062684,3.2392588,1196m/data=!3m2!1e3!4b1!4m6!3m5!1s0x47c350b9fa373b87:0x472d31056fc8ce39!8m2!3d51.2062684!4d3.2418337!16s%2Fg%2F1tf5jszl?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
          button_url="https://luwio.sport.vlaanderen/aanbod/freerunning-skateboarden"
          button_status="enabled"
        />
        <CardInfo
          title="wanyi kamp"
          date="27/07 - 02/08/2025"
          age="16j+"
          location="Wanyi park"
          price="300 - 350"
          note="
          •	Parkour (in/outdoor) <br>
          •	Bouw je eigen parkour park ! <br>
          •	Mountainboarden, waterpretpark, skaten en nog veel meer <br>
          <br>
          Slaapkamp, vervoer inbegrepen<br>
          <br>
          Dit is een Parkour avonturen kamp waarin je back to basics gaat. Gezellig kampvuurtje maken, avontuurlijke activiteiten, zelf je parkour park bouwen. "
          button_text="meer info"
          location_url="https://maps.app.goo.gl/PuCXRNWnnLwY5HLN6"
          button_url="https://app.twizzit.com/v2/form/MXdqWE54Uk9GWXdoSG1uS3ZzaDQ0dz09"
          button_status="enabled"
        />
        <CardInfo
          title="wanyi kamp"
          date="20/07 - 26/07/2025"
          age="12 - 15j"
          location="Wanyi park"
          price="300 - 350"
          note="
          •	Parkour (in/outdoor) <br>
          •	Bouw je eigen parkour park ! <br>
          •	Mountainboarden, waterpretpark, skaten en nog veel meer <br>
          <br>
          Slaapkamp, vervoer inbegrepen<br>
          <br>
          Dit is een Parkour avonturen kamp waarin je back to basics gaat. Gezellig kampvuurtje maken, avontuurlijke activiteiten, zelf je parkour park bouwen. "
          button_text="meer info"
          location_url="https://maps.app.goo.gl/PuCXRNWnnLwY5HLN6"
          button_url="https://app.twizzit.com/v2/form/U1RDVmQ0MVhFdkVpWmxZVkhGa09Cdz09"
          button_status="enabled"
        />
        <CardInfo
          title="parkour kamp"
          date="14/04 - 18/04/2025"
          age="9 - 14j"
          location="JC Tranzit Kortrijk"
          price=""
          note="
            •	Parkour (in/outdoor) <br>
            •	Kamp voor beginners <br>
            <br>
            Dagkamp (9u-12u) <br>
            <br>
            Ontdek Parkour/Freerunning in het urban sportspark van JC Tranzit. <br>
            Een kamp boordevol plezier met top lesgevers. "
          button_text="inschrijven"
          location_url="https://www.google.com/maps/place/JC+Tranzit/@50.8257012,3.2511592,603m/data=!3m2!1e3!4b1!4m6!3m5!1s0x47c33b1892dbf675:0x59d756700f2020ee!8m2!3d50.8257012!4d3.2537341!16s%2Fg%2F1v76ytg6?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
          button_url="https://vrijetijd.kortrijk.be/Home?shop=90BE5950-9B6E-4A2C-A401-CDE622CE63EF&url=%2fActivities%2fRegister%3frefresh%3dy%26id%3dfacecf6b-449c-ef11-a1e9-005056999076%26status%3d2"
          button_status="enabled"
        />
        <CardInfo
          title="parkour kamp"
          date="14/07 - 18/07/2025"
          age="9 - 14j"
          location="JC Tranzit Kortrijk"
          price=""
          note="
          •	Parkour (in/outdoor) <br>
          •	Kamp voor beginners <br>
          <br>
          Dagkamp (9u-12u) <br>
          <br>
          Ontdek Parkour/Freerunning in het urban sportspark van JC Tranzit. <br>
          Een kamp boordevol plezier met top lesgevers."
          button_text="nog niet beschikbaar"
          location_url="https://www.google.com/maps/place/JC+Tranzit/@50.8257012,3.2511592,603m/data=!3m2!1e3!4b1!4m6!3m5!1s0x47c33b1892dbf675:0x59d756700f2020ee!8m2!3d50.8257012!4d3.2537341!16s%2Fg%2F1v76ytg6?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
          button_url=""
          button_status="disabled"
        />
        <CardInfo
          title="Skate & parkour"
          date="07/07 - 11/07/2025"
          age="9 - 14j"
          location="Beastwood Br"
          price=""
          note="
          •	Parkour (in/outdoor) <br>
          •	Skate (indoor beastwood) <br>
          <br>
          Dagkamp <br>
          <br>
          Leer skaten en Parkour van de beste lesgevers. Verbeter je Parkour en skate skills. Geen ervaring in één van de disciplines? Geen probleem, je leert de basis op een veilige manier!"
          button_text="nog niet beschikbaar"
          location_url="https://www.google.com/maps/place/Skatepark+Beastwood/@51.1872903,3.1956852,598m/data=!3m2!1e3!4b1!4m6!3m5!1s0x47c351ec65238507:0x6f6bbb66dcc5b8f!8m2!3d51.1872903!4d3.1982601!16s%2Fg%2F11pxs4l0zv?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
          button_url=""
          button_status="disabled"
        />
        <CardInfo
          title="Circus & parkour"
          date="07/07 - 11/07/2025"
          age="6 - 10j"
          location="Sport Vlaanderen Br"
          price=""
          note="
          •	Circus <br>
          •	Parkour (indoor) <br>
          <br>
          Dagkamp <br>
          <br>
          Een entertainend kamp boordevol motorische ontwikkeling. <br>
          Je wordt ondergedompelt in een wereld van avontuurlijke fantasie. <br>
          Klimmen, springen, balanceren. "
          button_text="nog niet beschikbaar"
          location_url="https://www.google.com/maps/place/Sport+Vlaanderen+Brugge/@51.2062684,3.2392588,1196m/data=!3m2!1e3!4b1!4m6!3m5!1s0x47c350b9fa373b87:0x472d31056fc8ce39!8m2!3d51.2062684!4d3.2418337!16s%2Fg%2F1tf5jszl?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D"
          button_url=""
          button_status="disabled"
        />
      </CardSection>
    </main>
  );
}

export default KampenPage;