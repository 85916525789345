import React from 'react';
import '../../styles/CardInfo.css';
import Button from '../Button';
import { LiaCalendarDaySolid, LiaClockSolid, LiaBirthdayCakeSolid, LiaMapMarkerSolid, LiaStickyNoteSolid, LiaTagSolid } from "react-icons/lia";

function CardInfo({ 
  title, 
  date, 
  time, 
  age, 
  location, 
  location_url, 
  price, 
  note, 
  button_text, 
  button_url, 
  button_status 
}) {
  return (
    <div className='CardInfo'>
      <div className='content'>
        <h1>{title}</h1>
        <ul>
          {date && <li><LiaCalendarDaySolid /> {date}</li>}
          {time && <li><LiaClockSolid /> {time}</li>}
          {age && <li><LiaBirthdayCakeSolid /> {age}</li>}
          {location && (
            <li>
              <a href={location_url} target='_blank' rel='noopener noreferrer'>
                <LiaMapMarkerSolid /> {location}
              </a>
            </li>
          )}
          {price && <li><LiaTagSolid /> €{price}</li>}
          <li>
            {note && (
              <>
                <LiaStickyNoteSolid />
                <div className="tooltip">
                  &nbsp;Meer info
                  <span className="tooltiptext" dangerouslySetInnerHTML={{ __html: note }}></span>
                </div>
              </>
            )}
          </li>
        </ul>
      </div>
      <Button
        button_text={button_text}
        button_url={button_url}
        button_status={button_status}
      />
    </div>
  );
}

export default CardInfo;