import React from 'react';
import '../styles/banner.css'

function Banner(props) {
    return (
        <section id='banner'>
            <div className='gradient'></div>
            <img src={props.src} alt="" />
            <h1>{props.title}</h1>
        </section>
    )
}

export default Banner;