// pages/HomePage.js
import React from 'react';

// <=========CARDS=========> \\
import CardSection from '../components/cards/CardSection';
import CardInfo from '../components/cards/CardInfo';
import TitleSection from '../components/TitleSection';
import ImgSection from '../components/ImgSection';

import Banner from '../components/banner';

function Vrije_TrainingenPage() {
    return (
        <main>
        <Banner
          src="images/banner.jpg"
          title='vrije trainingen'
        />
        <CardSection>
          <CardInfo
            title="brugge wo"
            date="woensdag"
            time="7:30 pm - 9:30 pm"
            note="Bekijk betalings opties"
            age="16+"
            price="Per 10 beurten betalen."
            location="Sport Vlaanderen"
            location_url="https://www.google.nl/maps/dir//Sport+Vlaanderen+Brugge,+Nijverheidsstraat+112,+8310+Brugge,+België/@51.181369,3.1091142,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c350b9fa373b87:0x472d31056fc8ce39!2m2!1d3.2418239!2d51.206431!3e0?hl=nl&entry=ttu"
            button_text="volzet"
            button_url=""
            button_status="disabled"
          />
          <CardInfo
            title="brugge vr"
            date="vrijdag"
            time="6:30 pm - 8:30 pm"
            note="Bekijk betalings opties"
            age="16+"
            price="Per 10 beurten betalen."
            location="Sport Vlaanderen"
            location_url="https://www.google.nl/maps/dir//Sport+Vlaanderen+Brugge,+Nijverheidsstraat+112,+8310+Brugge,+België/@51.181369,3.1091142,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c350b9fa373b87:0x472d31056fc8ce39!2m2!1d3.2418239!2d51.206431!3e0?hl=nl&entry=ttu"
            button_text="volzet"
            button_url=""
            button_status="disabled"
          />
          <CardInfo
            title="kortrijk vr"
            date="vrijdag"
            time="6pm - 8pm"
            note=""
            age="16+"
            price="Gratis!"
            location="Tranzit"
            location_url="https://www.google.nl/maps/dir//Sport+Vlaanderen+Brugge,+Nijverheidsstraat+112,+8310+Brugge,+België/@51.181369,3.1091142,13z/data=!4m9!4m8!1m0!1m5!1m1!1s0x47c350b9fa373b87:0x472d31056fc8ce39!2m2!1d3.2418239!2d51.206431!3e0?hl=nl&entry=ttu"
            button_text="volzet"
            button_url=""
            button_status="disabled"
          />
        </CardSection>
        <TitleSection title="uitleg"/>
        <ImgSection
        title="wat is een vrije training?"
        text="Een vrije training is een parkoursessie waarbij er <b>geen les</b> wordt gegeven, maar je de vrijheid hebt om zelfstandig te trainen op een opstelling. De sessie start met een <b>gezamenlijke warming-up</b> om iedereen op te warmen en blessures te voorkomen. Daarna kun je zelf bepalen welke technieken, sprongen of combinaties je wilt oefenen. Het is de ideale gelegenheid om in je eigen tempo te trainen en specifieke vaardigheden te verbeteren."
        img="images/banner.jpg"
      />
      </main>
    );
}

export default Vrije_TrainingenPage;