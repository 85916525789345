import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

const SplitItemImg = ({ title, img, button_status, button_url, button_text }) => (
  <div>
    <h5>{title}</h5>
    <img src={img} alt="" loading="lazy" />
    <Button
      button_status={button_status}
      button_url={button_url}
      button_text={button_text}
    />
  </div>
);

SplitItemImg.propTypes = {
  title: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  button_status: PropTypes.string.isRequired,
  button_url: PropTypes.string.isRequired,
  button_text: PropTypes.string.isRequired,
};

export default SplitItemImg;