import React from 'react';
import PropTypes from 'prop-types';

const SplitItemText = ({ title, text }) => (
  <div className='SplitItemText'>
    <h5>{title}</h5>
    <p>{text}</p>
  </div>
);

SplitItemText.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default SplitItemText;