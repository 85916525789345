import React from 'react';
import PropTypes from 'prop-types';
import '../styles/SplitSection.css';

const SplitSection = ({ children }) => (
  <section className="triple-section">
    {children}
  </section>
);

SplitSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SplitSection;